<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">发票管理</span>
    </div>
    <div class="invoice">
      <div class="invoice-detail">
        <span class="invoice-title">默认发票信息</span>
        <el-button
          @click="showinvoicedetail"
          class="invoice-manage"
          type="primary"
          size="mini"
          >管理</el-button
        >
        <!-- <div>
        <br>
        <span class="invoice-message">默认抬头</span>
        <span class="invoice-message">{{this.default.Title}}</span>
      </div>
        <el-divider></el-divider>
      <div>
        <span class="invoice-message">发票类型</span>
        <span class="invoice-message">{{this.default.Type}}</span>
      </div>
       <el-divider></el-divider>
      <div>
        <span class="invoice-message">发票类型</span>
        <span class="invoice-message">{{this.default.Type}}</span>
      </div> -->
        <br /><br />
        <com-table
          class="grouplist"
          :headercellstyle="headercellstyle"
          :columns="invoicedetaillist"
          tableheight="125px"
          :data="tabledata2"
        >
        </com-table>
      </div>
    </div>

    <div class="content">
      <div>
        <div class="invoice-title">发票管理</div>
        <div class="invoice-message">可开发票金额</div>
        <span class="invoice-message2">{{ this.canInvoice }}</span>
        <el-button
          @click="applyinvoice"
          class="liveguidebtn"
          type="primary"
          size="medium"
          >申请发票</el-button
        >
      </div>
      <br />
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="invoicelist"
        :pagination="pagination"
        tableheight="calc(100vh - 550px)"
        :data="tabledata"
      >
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { invoicelist, invoicedetaillist } from "@/utils/tableList";
import navigation from "@/utils/navigation";

export default {
  // name: "invoicelist",
  data() {
    return {
      invoicelist,
      invoicedetaillist,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      tabledata2: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      title: "",
      default: {},
      canInvoice: "0",
    };
  },
  components: {
    ComTable,
  },
  mounted() {
    this.getinvoicelist();
    this.getinvoiceamount();
    this.getinvocealllist();
    this.getuserinfo();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let counter = 0;
      vm.checking = true;
      vm.fullPath = to.name;
      console.log("route name: " + vm.fullPath);
      if (vm.fullPath == "invoice") {
        let timer = setInterval(() => {
          counter++;
          if (counter > 1) {
            clearInterval(timer);
          }
          vm.getinvoicelist();
          vm.getinvoiceamount();
          vm.getinvocealllist();
        }, 5000);
      }
    });
  },
  methods: {
    getinvoicelist() {
      this.$store.dispatch("user/listinvoiceinfo").then((res) => {
        // console.log("invoice list: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.tabledata2 = [];
          for (let item of res.InvoiceInfoList) {
            if (item.Default && item.Email.length != 0) {
              this.tabledata2.push(item);
            }
          }
        }
      });
    },

    getinvoiceamount() {
      this.$store.dispatch("user/getinvoiceamountinfo").then((res) => {
        if (res.RetCode == 0) {
          if (res.CanInvoice >= 0) {
            this.canInvoice = (res.CanInvoice / 100.0).toFixed(2) + "元";
          }
        }
      });
    },
    getinvocealllist() {
      this.$store.dispatch("user/getlistinvoiceinfo").then((res) => {
        if (res.RetCode == 0) {
          this.tabledata = res.InvoiceList;
        }
      });
    },
    applyinvoice() {
      navigation.navigateTo({
        url: "applyinvoice",
        params: {},
      });
    },
    showinvoicedetail() {
      navigation.navigateTo({
        url: "invoicedetail",
        params: {},
      });
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>